import React from "react";
import { Section as StyledSection, Content } from "./styles";

interface SectionType {
  id?: string;
  palette?: string;
  shouldOverlap?: boolean;
  isCenterAlign?: boolean;
}

export const Section: React.FC<SectionType> = ({
  children,
  id,
  palette,
  shouldOverlap,
  isCenterAlign,
}) => (
  <StyledSection
    palette={palette}
    id={id}
    shouldOverlap={shouldOverlap}
    isCenterAlign={isCenterAlign}
  >
    <Content>{children}</Content>
  </StyledSection>
);
