import React from "react";
import { Button as StyledButton } from "./styles";
import { PaletteType } from "../../types/enums";

interface ButtonType {
  className?: string;
  fullWidth?: boolean;
  palette?: PaletteType;
}

export const Button: React.FC<ButtonType> = ({
  children,
  className,
  fullWidth,
  palette = "primary",
}) => (
  <StyledButton className={className} fullWidth={fullWidth} palette={palette}>
    {children}
  </StyledButton>
);
