import styled from "styled-components";
import { lightGrey, darkGrey } from "../../styles/colors";

export const Breadcrumbs = styled.div`
  background: ${lightGrey};
  color: ${darkGrey};
  height: 40px;
  font-size: 14px;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  max-width: 960px;
  padding: 0 20px;
  margin: 0 auto;
`;

export const Separator = styled.span`
  &::after {
    content: ">";
    padding: 0 8px;
  }
`;

export const Breadcrumb = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
