import React from "react";
import { Link as StyledLink } from "./styles";

interface LinkType {
  className?: string;
  to?: string;
  type?: string;
}

export const Link: React.FC<LinkType> = ({
  children,
  className,
  to = "",
  type = "text",
}) => (
  <StyledLink className={className} to={to} type={type}>
    {children}
  </StyledLink>
);
