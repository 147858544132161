import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (min-width: 850px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
