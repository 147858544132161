import React from "react"
import { Grid as StyledGrid } from "./styles"

interface GridType {
  className?: string
}

export const Grid: React.FC<GridType> = ({ children, className }) => (
  <StyledGrid className={className}>{children}</StyledGrid>
)
