import styled, { css } from "styled-components";
import { red } from "../../styles/colors";
import { Link as _Link } from "gatsby";

export const Link = styled(_Link)`
  color: ${red};
  text-decoration: none;

  &::after {
    ${(props) =>
      props.type === "text" &&
      css`
        content: " →";
      `}
  }
`;
