import React from "react";
import { Link } from "gatsby";
import { List, Grid, Button } from "./styles";

export const ServiceCardList = ({ children, showLink }) => (
  <List>
    <Grid>{children}</Grid>
    {showLink && (
      <Link to="/our-services">
        <Button palette="secondary">View more services</Button>
      </Link>
    )}
  </List>
);
