import styled from "styled-components";
// import { lightGrey, darkGrey } from "../../styles/colors";

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.5;

  p {
    margin: 10px 0;
  }

  ul {
    list-style: disc;
  }

  li {
    margin-left: 15px;
  }
`;
