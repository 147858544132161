import styled from "styled-components";
import { Link as _Link } from "../Link";
import { grey, black } from "../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 20px;
  background: ${grey};
  border-radius: 4px;
`;

export const Link = styled(_Link).attrs({
  type: "block",
})``;

export const Icon = styled.div``;

export const Content = styled.div`
  text-align: center;
`;

export const Heading = styled.h1`
  margin-top: 5px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.2;
  color: ${black};
`;
