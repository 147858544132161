import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Content } from "../components/Content";
import { Section } from "../components/Section";
import { SectionHeading } from "../components/SectionHeading";
import { ServiceCardList } from "../components/ServiceCardList";
import { ServiceCard } from "../components/ServiceCard";

interface ServicesPageProps extends PageProps {
  data: {
    allWordpressPage: any;
    allWordpressWpService: any;
  };
  pageContext: {
    id: string;
    slug: string;
    title: string;
  };
}

const Services: React.FC<ServicesPageProps> = ({ data, pageContext }) => (
  <Layout>
    <Breadcrumbs title={pageContext.title} />
    <Section>
      <SectionHeading>
        <span dangerouslySetInnerHTML={{ __html: pageContext.title }} />
      </SectionHeading>
      <Content dangerouslySetInnerHTML={{ __html: pageContext.content }} />
      <ServiceCardList>
        {data.allWordpressWpService.edges.map(
          ({ node: { id, title, slug } }) => (
            <ServiceCard
              key={id}
              icon="foo.png"
              title={title}
              href={`/services/${slug}`}
            />
          )
        )}
      </ServiceCardList>
    </Section>
  </Layout>
);

export const pageQuery = graphql`
  query {
    allWordpressWpService {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`;

export default Services;
