import styled, { css } from "styled-components";
import { red, white, black } from "../../styles/colors";

const primary = css`
  background: ${red};
  border-color: ${red};
  color: ${white};
`;

const secondary = css`
  background: transparent;
  border-color: ${black};
  color: ${black};
`;

const inverted = css`
  color: ${black};
  background: ${white};
`;

export const Button = styled.button`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  height: 50px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.1px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  cursor: pointer;

  ${(props) => props.palette === "primary" && primary}
  ${(props) => props.palette === "secondary" && secondary}
  ${(props) => props.palette === "inverted" && inverted}
`;
