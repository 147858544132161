import React from "react";
import { Link } from "gatsby";
import {
  Breadcrumbs as StyledBreadcrumbs,
  Content,
  Separator,
  Breadcrumb,
} from "./styles";

interface BreadcrumbsType {
  title: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsType> = ({ title }) => (
  <StyledBreadcrumbs>
    <Content>
      <Link to="/">Home</Link>
      <Separator />
      <Breadcrumb dangerouslySetInnerHTML={{ __html: title }} />
    </Content>
  </StyledBreadcrumbs>
);
