import styled, { css } from "styled-components";

export const Section = styled.section`
  padding: 75px 20px;

  ${(props) =>
    props.isCenterAlign &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.palette === "secondary" &&
    css`
      background: #f4f4f4;
    `}

  ${(props) =>
    props.palette === "transparent" &&
    css`
      background: transparent;
    `}

    ${(props) =>
      props.shouldOverlap &&
      css`
        margin-top: -95px;
        border-radius: 5px 5px 0 0;
        padding-top: 0;
      `}
`;

export const Content = styled.div`
  max-width: 940px;
  margin: 0 auto;
`;
