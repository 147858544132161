import React from "react";
import { Wrapper, Link, Icon, Content, Heading } from "./styles";

interface ServiceCardType {
  icon: string;
  title: string;
  href: string;
}

export const ServiceCard: React.FC<ServiceCardType> = ({
  icon,
  title,
  href,
}) => (
  <Wrapper>
    {/* <Link to={href}> */}
    <Icon href={icon} />
    <Content>
      <Heading>{title}</Heading>
    </Content>
    {/* </Link> */}
  </Wrapper>
);
